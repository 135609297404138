/* src/App.css */
.App-header, .App-intro {
  margin: 0 20px; 
}

.App {
  font-family: 'Inter', sans-serif;
  max-width: 800px;  
  margin: 0 auto;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.App-header h1 {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.App-header nav a {
  margin-left: 20px;
  text-decoration: none;
  color: #333;
}

.App-header nav a:hover {
  color: #007bff;
}


.App-intro p {
  margin: 20px 0;
}

.App-opensource h2 {
  margin-top: 40px;
}

/* ... Add more styling as needed ... */
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

h5{
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}