.project-section {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center; 

  }
  
.project-card {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e1e4e8;
    padding: 15px 0;
    max-width: 95%; /* or any value less than 100% */
    margin: 0 20px; /* 20px margin on left and right */
}
.project-card > div:first-child {
    flex: 1;
}
  
  .project-title {
    font-weight: 500;
    color: #333;
  }
  
  .project-role {
    color: #666;
  }
  
  .project-duration {
    color: #999;
  }

  .project-title a {
    text-decoration: none;
    color: #333;   /* You can use any color you want */
    transition: color 0.3s;
  }
  
  .project-title a:hover {
    color: #007bff;   /* Change color on hover */
  }
  
  .proj-title {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 30px;
    /* margin-left:15px; */
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #0a0a0a;
}