
h4 {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 30px;
    margin-left:15px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #666;
}

.rounded-map {
    border-radius: 20px;   
    overflow:hidden;       
    margin:0 20px; 
    /* padding-right:-10px; */
}


